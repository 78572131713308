<template>
  <div class="mt-3">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <a
          class="btn-create"
          variant="primary"
          href="https://wichee.emessage.it/CustomDefaultPage/WICHEE/eMessage%20Mio_it.html"
          target="_blank"
          >Vai al pannello di eMessage</a
        >
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import ShortcutMixin from "@/mixins/ShortcutMixin";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "agency_setup",
      id: this.$store.state.auth.settings.id,
      form: {
        agency_setup: {
          attribute_EMUSR_value: null,
          attribute_EMPWD_value: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
  },
  methods: {},
  created() {
    this.isLoading = false;
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
<style lang="scss" scoped></style>
