<template>
  <div class="mt-3">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="" autocomplete="off">
              <b-card header="Credenziali eMessage" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_EMUSR_value.label"
                        vid="attribute_EMUSR_value"
                        :label="beForm[rep].attribute_EMUSR_value.label"
                        v-model="form[rep].attribute_EMUSR_value"
                        :rules="{ required: true }"
                        placeholder="Inserisci lo username"
                        autocomplete="one-time-code"
                      />
                    </div>
                    <div class="col-md-3">
                      <base-input
                        :name="beForm[rep].attribute_EMPWD_value.label"
                        vid="attribute_EMPWD_value"
                        :label="beForm[rep].attribute_EMPWD_value.label"
                        type="password"
                        v-model="form[rep].attribute_EMPWD_value"
                        :rules="{ required: true }"
                        placeholder="Inserisci la password"
                        autocomplete="one-time-code"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <a
                  :href="computedEmessageLink"
                  class="btn-create"
                  variant="primary"
                  title="Registrati su eMessage"
                  target="_blank"
                  v-if="!form[rep].attribute_EMUSR_value"
                  ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
                  Registrati</a
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import ShortcutMixin from "@/mixins/ShortcutMixin";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "agency_setup",
      id: this.$store.state.auth.settings.id,
      form: {
        agency_setup: {
          attribute_EMUSR_value: null,
          attribute_EMPWD_value: null,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseInput,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;

      // Update agency_setup form
      this.update(this.rep, this.id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione completata: Dati di agenzia aggiornati`,
          });
          // this.shortcut("agency_setups.index", null, null);
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
          this.isLoading = false;
        });

      // Update EMUSR
      if (this.form[this.rep].attribute_EMUSR_value)
        this.$store.state.auth.settings.attribute_EMUSR_value =
          this.form[this.rep].attribute_EMUSR_value;

      // Update EMPWD
      if (this.form[this.rep].attribute_EMPWD_value)
        this.$store.state.auth.settings.attribute_EMPWD_value =
          this.form[this.rep].attribute_EMPWD_value;
    },
    fetch() {
      this.isLoading = true;

      // Fetch edit agency_setup form
      this.fetchEditForm(this.rep, this.id)
        .then(() => {
          this.initMandatoryFields("agency_setup");
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetch();
  },
  computed: {
    rep() {
      return this.repository;
    },
    computedEmessageLink() {
      return `https://wichee.emessage.it/SignUpWichee.aspx?PIN=${this.$store.state.auth.settings.code}`;
    },
  },
};
</script>
